var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-header-wrapper',[_c('div',{staticClass:"a-card"},[_c('a-form',{attrs:{"form":_vm.campaignForm}},[_c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"forceRender":true,"tab":_vm.$t('title.basicInfo')}},[_c('a-form-item',{attrs:{"has-feedback":"","label":_vm.$t('field.campaignCode'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'campaignCodeInput',
                  {
                    rules: _vm.$rules([
                      { required: true, message: '请输入' + this.$t('field.campaignCode') },
                      { max: 8, message: this.$t('field.campaignCode') + '长度小于等于8' },
                      {
                        type: 'checkExists',
                        url: '/api/marketing/campaign/checkCampaignCodeExists',
                        existIdValue: _vm.data.campaignId,
                        checkField: 'campaignCode',
                        idFieldName: 'campaignId',
                        tableName: 'campaign',
                        checkFieldBaseValue: _vm.data.campaignTypeCode
                      }
                    ])
                  }
                ]),expression:"[\n                  'campaignCodeInput',\n                  {\n                    rules: $rules([\n                      { required: true, message: '请输入' + this.$t('field.campaignCode') },\n                      { max: 8, message: this.$t('field.campaignCode') + '长度小于等于8' },\n                      {\n                        type: 'checkExists',\n                        url: '/api/marketing/campaign/checkCampaignCodeExists',\n                        existIdValue: data.campaignId,\n                        checkField: 'campaignCode',\n                        idFieldName: 'campaignId',\n                        tableName: 'campaign',\n                        checkFieldBaseValue: data.campaignTypeCode\n                      }\n                    ])\n                  }\n                ]"}],attrs:{"name":"data.campaignCodeInput","placeholder":'请输入' + this.$t('field.campaignCode'),"maxLength":8},model:{value:(_vm.data.campaignCodeInput),callback:function ($$v) {_vm.$set(_vm.data, "campaignCodeInput", $$v)},expression:"data.campaignCodeInput"}})],1),_c('a-form-item',{attrs:{"has-feedback":"","label":_vm.$t('field.campaignName'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'campaignName',
                  {
                    rules: _vm.$rules([
                      { required: true, message: '请输入' + this.$t('field.campaignName') },
                      { max: 100, message: this.$t('field.campaignName') + '长度小于等于100' },
                      {
                        type: 'checkExists',
                        url: '/api/marketing/campaign/checkCampaignNameExists',
                        existIdValue: _vm.data.campaignId,
                        checkField: 'campaignName',
                        idFieldName: 'campaignId',
                        tableName: 'campaign'
                      }
                    ])
                  }
                ]),expression:"[\n                  'campaignName',\n                  {\n                    rules: $rules([\n                      { required: true, message: '请输入' + this.$t('field.campaignName') },\n                      { max: 100, message: this.$t('field.campaignName') + '长度小于等于100' },\n                      {\n                        type: 'checkExists',\n                        url: '/api/marketing/campaign/checkCampaignNameExists',\n                        existIdValue: data.campaignId,\n                        checkField: 'campaignName',\n                        idFieldName: 'campaignId',\n                        tableName: 'campaign'\n                      }\n                    ])\n                  }\n                ]"}],attrs:{"name":"data.campaignName","placeholder":'请输入' + this.$t('field.campaignName')},model:{value:(_vm.data.campaignName),callback:function ($$v) {_vm.$set(_vm.data, "campaignName", $$v)},expression:"data.campaignName"}})],1),_c('SelectMarketplace',{attrs:{"data":_vm.data}})],1),_c('a-tab-pane',{key:"5",attrs:{"forceRender":true,"tab":_vm.$t('title.picSetting')}},[_c('PicUrlListEdit',{style:({ marginBottom: '10px' }),attrs:{"field-name":"picList","pic-url-list":_vm.data.picList}})],1)],1)],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary","icon":"save"},on:{"click":_vm.handleCampaignSubmit}},[_vm._v(_vm._s(_vm.$t('button.submit')))]),(_vm.data.campaignId)?_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary","icon":"play-circle"},on:{"click":_vm.handlePreview}},[_vm._v(" "+_vm._s(_vm.$t('button.preview'))+" ")]):_vm._e()],1)],1)],1),_c('PhonePreview',{ref:"phonePreview"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }