<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper>
    <div class="a-card">
      <a-form :form="campaignForm">
        <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
          <a-tabs default-active-key="1">
            <a-tab-pane :forceRender="true" key="1" :tab="$t('title.basicInfo')">
              <!-- <a-form-item
                has-feedback
                :label="$t('field.campaignTypeName')"
                :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
                >
                <a-select
                  v-decorator="[
                    'campaignTypeCode',
                    {
                      getValueFromEvent: oncCampaignTypeChanged,
                      rules: [{ required: true, message: '请输入' + this.$t('field.campaignTypeCode') }]
                    }
                  ]"
                  mode="default"
                  :disabled="data.campaignId"
                  v-model="data.campaignTypeCode"
                >
                  <a-select-option
                    v-for="(item, index) in codeList.campaignTypeList"
                    :key="index"
                    :item="item"
                    :campaignTypeName="item.campaignTypeName"
                    :campaignTypeCode="item.campaignTypeCode"
                    :campaignTypeId="item.campaignTypeId"
                    :value="item.campaignTypeCode"
                  >{{ item.campaignTypeCode }}-{{ item.campaignTypeName }}
                  </a-select-option>
                </a-select>
              </a-form-item> -->
              <a-form-item
                has-feedback
                :label="$t('field.campaignCode')"
                :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
              >
                <a-input
                  v-decorator="[
                    'campaignCodeInput',
                    {
                      rules: $rules([
                        { required: true, message: '请输入' + this.$t('field.campaignCode') },
                        { max: 8, message: this.$t('field.campaignCode') + '长度小于等于8' },
                        {
                          type: 'checkExists',
                          url: '/api/marketing/campaign/checkCampaignCodeExists',
                          existIdValue: data.campaignId,
                          checkField: 'campaignCode',
                          idFieldName: 'campaignId',
                          tableName: 'campaign',
                          checkFieldBaseValue: data.campaignTypeCode
                        }
                      ])
                    }
                  ]"
                  name="data.campaignCodeInput"
                  v-model="data.campaignCodeInput"
                  :placeholder="'请输入' + this.$t('field.campaignCode')"
                  :maxLength="8"
                />
              </a-form-item>
              <a-form-item
                has-feedback
                :label="$t('field.campaignName')"
                :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
              >
                <a-input
                  v-decorator="[
                    'campaignName',
                    {
                      rules: $rules([
                        { required: true, message: '请输入' + this.$t('field.campaignName') },
                        { max: 100, message: this.$t('field.campaignName') + '长度小于等于100' },
                        {
                          type: 'checkExists',
                          url: '/api/marketing/campaign/checkCampaignNameExists',
                          existIdValue: data.campaignId,
                          checkField: 'campaignName',
                          idFieldName: 'campaignId',
                          tableName: 'campaign'
                        }
                      ])
                    }
                  ]"
                  name="data.campaignName"
                  v-model="data.campaignName"
                  :placeholder="'请输入' + this.$t('field.campaignName')"
                />
              </a-form-item>
             
              <SelectMarketplace :data="data" />
              
            </a-tab-pane>
            
            <!-- <a-tab-pane :forceRender="true" key="3" :tab="$t('title.conditionSetting')">
              <a-form-item
                :label="$t('field.marketingConditionType')"
                :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
              >
                {{ data.marketingConditionType }}
              </a-form-item>
              <a-form-item
                v-if="
                  data.marketingConditionType === '品牌' ||
                    data.marketingConditionType === '品牌SPU' ||
                    data.marketingConditionType === '品牌SKU' ||
                    data.marketingConditionType === '品类品牌SPU' ||
                    data.marketingConditionType === '品类品牌SKU'
                "
                :label="$t('field.brandCode')"
                :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
              >
                <a-select v-model="data.condition.brandCode" mode="default" @change="onBrandChanged">
                  <a-select-option
                    v-for="(item, index) in codeList.brandCode"
                    :key="index"
                    :brandName="item.brandName"
                    :value="item.brandCode"
                  >{{ item.brandCode }}-{{ item.brandName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                v-if="
                  data.marketingConditionType === '品类SPU' ||
                    data.marketingConditionType === '品类SKU' ||
                    data.marketingConditionType === '品类品牌SPU' ||
                    data.marketingConditionType === '品类品牌SKU'
                "
                :label="$t('field.categoryCode')"
                :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
              >
                <a-select v-model="data.condition.categoryCode" mode="default" @change="onCategoryChanged">
                  <a-select-option
                    v-for="(item, index) in codeList.categoryCode"
                    :key="index"
                    :categoryId="item.categoryId"
                    :serviceList="item.serviceList"
                    :categoryName="item.categoryName"
                    :value="item.categoryCode"
                  >{{ item.categoryCode }}-{{ item.categoryName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <SpuListEdit
                v-if="
                  data.marketingConditionType === '品牌SPU' ||
                    data.marketingConditionType === '品类SPU' ||
                    data.marketingConditionType === '品类品牌SPU' ||
                    data.marketingConditionType === 'SPU'
                "
                :condition="data.condition"
                :spu-list="data.condition.spuList"
              ></SpuListEdit>
              <SkuListEdit
                v-if="
                  data.marketingConditionType === '品牌SKU' ||
                    data.marketingConditionType === '品类SKU' ||
                    data.marketingConditionType === '品类品牌SKU' ||
                    data.marketingConditionType === 'SKU'
                "
                :condition="data.condition"
                :sku-list="data.condition.skuList"
              ></SkuListEdit>
            </a-tab-pane> -->
            
            <a-tab-pane :forceRender="true" key="5" :tab="$t('title.picSetting')">
             
              <PicUrlListEdit
                field-name="picList"
                :pic-url-list="data.picList"
                :style="{ marginBottom: '10px' }"
              ></PicUrlListEdit>
             
            </a-tab-pane>
          </a-tabs>
        </a-card>
        <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
          <a-button style="margin-left: 8px" type="primary" @click="handleCampaignSubmit" icon="save">{{
            $t('button.submit')
          }}</a-button>
          <a-button
            v-if="data.campaignId"
            type="primary"
            style="margin-left: 8px"
            @click="handlePreview"
            icon="play-circle"
          >
            {{ $t('button.preview') }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <PhonePreview ref="phonePreview" />
  </page-header-wrapper>
</template>

<script>
import { getCampaignData, saveCampaignData } from '@/api/marketing/campaign'
import { listCampaignType } from '@/api/marketing/campaignType'
import moment from 'moment'
// import MultilingualInfo from '@/views/marketing/components/MultilingualInfo'
import SelectMarketplace from '@/views/component/SelectMarketplace'
import SelectSiteList from '@/views/component/SelectSiteList'
// import SpuListEdit from '@/views/component/SpuListEdit'
// import SkuListEdit from '@/views/component/SkuListEdit'
import PicUrlEdit from '@/views/component/PicUrlEdit'
import PicUrlListEdit from '@/views/component/PicUrlListEdit'
// import PicDisplayListEdit from '@/views/component/PicDisplayListEdit'
// import StringListEdit from '@/views/component/StringListEdit'
import { listCategory } from '@/api/goods/category'
import { listBrand } from '@/api/goods/brand'
import { listMarketplace } from '@/api/system/marketplace'
import PhonePreview from '@/views/component/PhonePreview'
export default {
  name: 'EditCampaign',
  props: {},
  components: {
    PhonePreview,
    // MultilingualInfo,
    SelectMarketplace,
    SelectSiteList,
    // SpuListEdit,
    // SkuListEdit,
    PicUrlEdit,
    PicUrlListEdit,
    // PicDisplayListEdit,
    // StringListEdit
  },
  data () {
    return {
      currencySymbol: '',
      moment,
      campaignForm: this.$form.createForm(this),
      codeList: {
        categoryCode: [],
        brandCode: [],
        campaignTypeList: []
      },
      skuCondition: {
        isFirstSku: 1
      },
      spuCondition: {},
      loading: false,
      selectedSpuList: [],
      data: {
        marketplaceCode: '',
        siteList: [],
        picList: [],
        multilingualInfoList: [],
        detailPicDisplayList: [],
        topMessageList: [],
        listPicUrl: '',
        languageData: {},
        discount: {
          minTotalAmount: 0,
          pricePercent: 0,
          shippingFeeAmountPercent: 0,
          priceAmount: 0,
          shippingFeeAmount: 0
        },
        condition: {
          skuList: [],
          spuList: []
        }
      },
      selectedCampaignType: {},
      startTime: [],
      fixedDT: [],
      timeLimitDT: '',
      validDateFlg: false
    }
  },
  mounted () {
    console.log(this.$route.query.type)
    if (this.$route.query.type === 'edit') {
      this.getCampaignData({ campaignId: this.$route.query.campaignId })
    } else {
    }
    // listCampaignType({}).then(res => {
    //   this.codeList.campaignTypeList = res.data
    // })
    // this.listCategory()
    // this.listBrand()
    this.getCurrencySymbol()
  },
  methods: {
    getCurrencySymbol () {
      listMarketplace({}).then(res => {
        const marketplaceCode = this.data.marketplaceCode
        const list = res.data.filter(item => item.marketplaceCode === marketplaceCode)
        const country = (list[0] || {}).country || ''
        this.currencySymbol = this.getCode('currencySymbol', country)
      })
    },
    // 编辑回显数据
    getCampaignData (data) {
      const that = this
      getCampaignData(data)
        .then(res => {
          console.log('获取数据成功', data, res)
          if (!res.condition) {
            res.condition = {
              skuList: [],
              spuList: []
            }
          }
          if (!res.topMessageList) {
            res.topMessageList = []
          }
          if (!res.condition.spuList) {
            res.condition.spuList = []
          }
          if (!res.condition.skuList) {
            res.condition.skuList = []
          }
          if (!res.discount) {
            res.discount = {
              minTotalAmount: 0,
              pricePercent: 0,
              shippingFeeAmountPercent: 0,
              priceAmount: 0,
              shippingFeeAmount: 0
            }
          } else {
            if (res.discount.minTotalAmount) {
              res.discount.minTotalAmountShow = res.discount.minTotalAmount / 100
            }
            if (res.discount.priceAmount) {
              res.discount.priceAmountShow = res.discount.priceAmount / 100
            }
            if (res.discount.shippingFeeAmount) {
              res.discount.shippingFeeAmountShow = res.discount.shippingFeeAmount / 100
            }
          }
          if (!res.picList) {
            res.picList = []
          }
          if (!res.detailPicDisplayList) {
            res.detailPicDisplayList = []
          }
          if (!res.picList) {
            res.picList = []
          }
          if (!res.detailPicDisplayList) {
            res.detailPicDisplayList = []
          }
          if (res.timeLimitType === '固定期间') {
            this.fixedDT = [res.startTimeDate + '' + res.startTimeTime, res.endTimeDate + '' + res.endTimeTime]
            res.fixedDT = this.fixedDT
          } else if (res.timeLimitType === '固定截止时间') {
            this.timeLimitDT = res.endTimeDate + '' + res.endTimeTime
            res.timeLimitDT = this.timeLimitDT
          } else if (res.timeLimitType === '领取后生效') {
            if (res.validDateCount === 0) {
              this.validDateFlg = true
              res.validDateFlg = this.validDateFlg
            }
          }
          that.data = res
          this.getCurrencySymbol()
          this.$nextTick(() => {
            const formData = {}
            Object.keys(that.campaignForm.getFieldsValue()).map(key => (formData[key] = res[key]))
            that.campaignForm.setFieldsValue(formData)
            console.log('获取优惠活动数据成功', data, res, that.campaignForm)
          })
        })
        .catch(res => {
          console.log('获取数据失败', res)
        })
    },
    // handler
    handleCampaignSubmit (event) {
      console.log('handleCampaignSubmit', event, this.data)
      const that = this
      this.campaignForm.validateFieldsAndScroll((err, values) => {
        console.log('handleCampaignSubmit-errors', err)
        if (err && Object.keys(err).length > 0) {
          this.alertError(err)
          return
        }
        if (this.data.timeLimitType === '固定期间') {
          this.data.startTimeTime = this.fixedDT[0].substr(8)
          this.data.startTimeDate = this.fixedDT[0].substr(0, 8)
          this.data.endTimeTime = this.fixedDT[1].substr(8)
          this.data.endTimeDate = this.fixedDT[1].substr(0, 8)
        } else if (this.data.timeLimitType === '固定截止时间') {
          this.data.endTimeTime = this.timeLimitDT.substr(8)
          this.data.endTimeDate = this.timeLimitDT.substr(0, 8)
        } else if (this.data.timeLimitType === '领取后生效') {
          if (this.validDateFlg) {
            this.data.validDateCount = 0
          }
        }
        const request = Object.assign({ ...that.data, ...that.campaignForm.getFieldsValue() })
        if (request.discount.minTotalAmountShow) {
          request.discount.minTotalAmount = request.discount.minTotalAmountShow * 100
        }
        if (request.discount.priceAmountShow) {
          request.discount.priceAmount = request.discount.priceAmountShow * 100
        }
        if (request.discount.shippingFeeAmountShow) {
          request.discount.shippingFeeAmount = request.discount.shippingFeeAmountShow * 100
        }
        console.log('request - handleCampaignSubmit', JSON.stringify(request))
        saveCampaignData(request)
          .then(res => {
            console.log('saveCampaignData:', that.data, res)
            that.$message.success('操作成功')
            this.$router.push({
              path: '/marketing/CampaignList',
              name: 'CampaignList'
            })
          })
          .catch(res => {
            that.$message.error('操作失败,' + res.message)
          })
      })
    },
    // languageData (data) {
    //   console.log('子组件', data)
    //   this.data.multilingualInfoList = data
    // },
    oncCampaignTypeChanged (value, option) {
      console.log(option.data.attrs.item)
      this.data.campaignTypeId = value
      this.data.campaignTypeCode = option.data.attrs.item.campaignTypeCode
      this.data.campaignTypeName = option.data.attrs.item.campaignTypeName
      this.data.marketingConditionType = option.data.attrs.item.marketingConditionType
      this.data.marketingDiscountType = option.data.attrs.item.marketingDiscountType
      this.selectedCampaignType = option.data.attrs.item
      return value
    },
    siteDataChange (data) {
      console.log(data)
      this.data.siteCodeList = data
    },
    getMultilingualData (data) {
      this.data.multilingualInfoList = data
    },
    // 品牌下拉框
    listBrand () {
      const that = this
      listBrand({})
        .then(res => {
          // console.log(res)
          that.codeList.brandCode = res.data
        })
        .catch(res => {
          // console.log(res)
        })
    },
    onBrandChanged (value, option) {
      console.log(option.data.attrs.brandName)
      this.data.condition.brandCode = value
      this.data.condition.brandName = option.data.attrs.brandName
    },
    // 管理分类下拉框
    listCategory () {
      const that = this
      listCategory({})
        .then(res => {
          // console.log(res)
          that.codeList.categoryCode = res.data
        })
        .catch(res => {
          // console.log(res)
        })
    },
    // 管理分类下拉框
    onCategoryChanged (value, option) {
      console.log(value)
      this.data.condition.categoryCode = value
      this.data.condition.categoryName = option.data.attrs.categoryName
      this.data.condition.categoryId = option.data.attrs.categoryId
    },
    /**
     * 预览
     */
    handlePreview () {
      this.$refs.phonePreview.show('/#/topic/?campaignCode=' + this.data.campaignCode)
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}

.ant-calendar-picker {
  width: 100%;
}

.ant-calendar-picker {
  width: 100% !important;
}
</style>
